import $ from 'jquery';
import suggestions from 'suggestions-jquery';
import Inputmask from 'inputmask';
import {
  validateForm,
  initPasswordEye,
} from '../validator/validator';
import {
  setStatus,
  setTextareaAutoHeight,
} from '../../utils/utils';

// Маска для ИНН
const innMask = new Inputmask('9{10,12}', {
  autoUnmask: true,
  showMaskOnHover: false,
  showMaskOnFocus: false,
  placeholder: '',
});

document.querySelectorAll('.input-inn').forEach((el) => {
  innMask.mask(el);
});

const setInputHeight = (field) => {
  field.style.height = `${field.scrollHeight}px`;
}

// Находим форму входа и навешиваем валидацию.
const signInForm = document.querySelector('#sign-form');
if (signInForm) {
  const token = "e2eeb413a16819871f748c5de52aaaeee3059d46"; // мой личный ключ
  const innInput = signInForm.querySelector('#inn');

  validateForm('#sign-form');
  initPasswordEye('#sign-form');

  const initInnHints = () => {
    if (innInput) {
      const kppInput = signInForm.querySelector('#kpp');
      const ogrnInput = signInForm.querySelector('#ogrn');
      const companyNameInput = signInForm.querySelector('#company-name');
      const companyShortNameInput = signInForm.querySelector('#company-short-name');
      const addressInput = signInForm.querySelector('#address');

      $('#inn').suggestions({
        token: token,
        type: 'PARTY',
        minChars: 8,
        formatResult: function (value, currentValue, suggestion, options) {

          const that = this;
          const highlightMatches = that.highlightMatches(suggestion.data.inn, currentValue, suggestion, options);
          // const postalCode = suggestion.data.address.data.postal_code;

          return `${value}, ИНН: ${highlightMatches}`;
        },
        // Вызывается при получении ответа от сервера. В suggestions передается массив полученных подсказок.
        // onSearchComplete: function (query, suggestions) {
        //   console.log(query);
        //   console.log(suggestions);
        // },
        // Вызывается, когда пользователь выбирает одну из подсказок.
        onSelect: function (suggestion) {
          if (innInput) {
            innInput.value = suggestion.data.inn;
          }

          if (kppInput) {
            kppInput.value = suggestion.data.kpp;
            kppInput.nextElementSibling.value = kppInput.value;

            if (kppInput.value != '') {
              kppInput.classList.remove('validator__input--error');
            } else {
              kppInput.classList.add('validator__input--error');
            }
          }

          if (ogrnInput) {
            ogrnInput.value = suggestion.data.ogrn;
            ogrnInput.nextElementSibling.value = ogrnInput.value;

            if (ogrnInput.value != '') {
              ogrnInput.classList.remove('validator__input--error');
            } else {
              ogrnInput.classList.add('validator__input--error');
            }
          }

          if (companyNameInput) {
            companyNameInput.value = suggestion.data.name.full_with_opf;
            companyNameInput.nextElementSibling.value = companyNameInput.value;
            setInputHeight(companyNameInput.nextElementSibling);

            if (companyNameInput.value != '') {
              companyNameInput.classList.remove('validator__input--error');
            } else {
              companyNameInput.classList.add('validator__input--error');
            }
          }

          if (companyShortNameInput) {
            companyShortNameInput.value = suggestion.data.name.short_with_opf;
            companyShortNameInput.nextElementSibling.value = companyShortNameInput.value;
            setInputHeight(companyShortNameInput.nextElementSibling);

            if (companyShortNameInput.value != '') {
              companyShortNameInput.classList.remove('validator__input--error');
            } else {
              companyShortNameInput.classList.add('validator__input--error');
            }
          }

          if (addressInput) {
            addressInput.value = suggestion.data.address.value;
            addressInput.nextElementSibling.value = addressInput.value;
            setInputHeight(addressInput.nextElementSibling);

            if (addressInput.value != '') {
              addressInput.classList.remove('validator__input--error');
            } else {
              addressInput.classList.add('validator__input--error');
            }
          }
        },

        /* Вызывается, когда пользователь не выбрал ни одну из подсказок */
        onSelectNothing: function () {
          if (kppInput) {
            kppInput.value = '';
            kppInput.nextElementSibling.value = kppInput.value;
          }

          if (ogrnInput) {
            ogrnInput.value = '';
            ogrnInput.nextElementSibling.value = ogrnInput.value;
          }

          if (companyNameInput) {
            companyNameInput.value = '';
            companyNameInput.nextElementSibling.value = companyNameInput.value;
            companyNameInput.nextElementSibling.style.height = '';
          }

          if (companyShortNameInput) {
            companyShortNameInput.value = '';
            companyShortNameInput.nextElementSibling.value = companyShortNameInput.value;
            companyShortNameInput.nextElementSibling.style.height = '';
          }

          if (addressInput) {
            addressInput.value = '';
            addressInput.nextElementSibling.value = addressInput.value;
            addressInput.nextElementSibling.style.height = '';
          }
        }
      });
    }
  }

  initInnHints();

  const initPostAdressHints = () => {
    const addressInput = signInForm.querySelector('#post-address');
    if (addressInput) {
      const postCodeInput = signInForm.querySelector('#postcode');
      const errorHouse = addressInput.parentElement.parentElement.querySelector('.validator__error-container');
      setTextareaAutoHeight('#sign-form textarea');

      $('#fake-address').suggestions({
        token,
        type: 'ADDRESS',
        onSelect(suggestion) {
          if (!suggestion.data.house) {
            addressInput.value = '';
            errorHouse.innerHTML = '<div class="validator__error"">Укажите полный адрес</div>';

            return false;
          }
          if (suggestion.data.house) {
            addressInput.value = suggestion.value;
            errorHouse.innerHTML = '';
          }

          if (postCodeInput) {
            postCodeInput.value = suggestion.data.postal_code;
            postCodeInput.nextElementSibling.value = postCodeInput.value;
          }
        },
        onSelectNothing() {
          addressInput.value = '';
          addressInput.nextElementSibling.value = addressInput.value;

          const alert = document.querySelector('#alert--request').content.querySelector('.alert');
          alert.classList.add(setStatus('error'));

          const container = document.querySelector('#alert--request').content.querySelector('.alert__container');
          container.innerHTML = '<p class="alert__text"><b>Выберите адрес из списка!</b></p>';

          window.Corners5ProjectLayout.summonAlert("#alert--request");

          if (postCodeInput) {
            postCodeInput.value = '';
            postCodeInput.nextElementSibling.value = postCodeInput.value;
          }
        },
        // formatResult: function (value, currentValue, suggestion, options) {

        //   const that = this;
        //   const highlightMatches = that.highlightMatches(value, currentValue, suggestion, options);
        //   const postalCode = suggestion.data.postal_code;

        //   if (postalCode) {
        //     return `Индекс: ${postalCode}, ${highlightMatches}`;
        //   } else {
        //     return `${highlightMatches}`;
        //   }
        // },
      });
    }
  };

  // initPostAdressHints();
  setTextareaAutoHeight('#sign-form textarea');
}